.payWrapper {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding-top: 100px;
	background-color: #eee;
	height: 100vh;
}
.payWrapper .innerWrapper {
	width: 500px;
	background-color: white;
	padding: 20px;
	border-radius: 5px;
}
.payWrapper .innerWrapper .title {
	width: 100%;
	text-align: center;
	font-weight: 600;
	color: #383838;
	margin-bottom: 30px;
}
.payWrapper .innerWrapper label {
	display: block;
	font-size: 13px;
	margin-bottom: 5px;
	font-weight: 500 !important;
}
.payWrapper .innerWrapper .row {
	width: 100%;
	margin-bottom: 15px;
}
.payWrapper .innerWrapper .row input {
	width: 100%;
	height: 35px;
	border: 1px solid #ccc;
	padding-left: 10px;
	border-radius: 5px;
}
.payWrapper .innerWrapper .row input:focus {
	outline: none;
}
.payWrapper .innerWrapper .rowPaymentInput {
	border: 1px solid #ccc;
	height: 35px;
	padding-top: 8px;
	padding-left: 10px;
	border-radius: 5px;
	margin-bottom: 15px;
}
.payWrapper .innerWrapper .addressWrapper .btnContainer {
	width: 100%;
	display: flex;
	justify-content: flex-end;
	margin-top: 30px;
}
/* .payWrapper .innerWrapper .addressWrapper .btnContainer button {
	cursor: pointer;
	background-color: #3166ec;
	width: 150px;
	border: none;
	height: 40px;
	color: white;
	border-radius: 5px;
	font-weight: 500;
} */

button:disabled {
    opacity: 0.5;
    cursor: default;
}