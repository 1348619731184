@import url('https://code.ionicframework.com/ionicons/2.0.1/css/ionicons.min.css');
@import url('https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.3.0/css/flag-icon.min.css');
@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,400i,700');

@import '~admin-lte/plugins/fontawesome-free/css/all.min.css';
@import '~admin-lte/plugins/icheck-bootstrap/icheck-bootstrap.min.css';
@import '~admin-lte/dist/css/adminlte.min.css';

@import '~react-toastify/dist/ReactToastify.min.css';

/* #root {
  width: 100vw;
  height: 100vh;
} */

.main-header .navbar-nav .nav-item .nav-link {
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}

span.required {
  color: red;
}

.card .scroll{
  overflow-y: scroll;
  max-height: 68vh;
  position: relative;
}

.otp-field {
  width: 38px;
  margin-right: 10px;
  padding-left: 12px;
  height: 40px;
}

.capitalize {
  text-transform: capitalize;
}

.loader {
  border: 10px solid #f3f3f3;
  border-top: 10px solid #3498db;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  animation: spin 1s linear infinite;
}

.pos-center {
  position: fixed;
  top: calc(50% - 40px);
  left: calc(50% - 40px);
}

.menu-disabled {
  cursor: not-allowed !important;
  color: #888c95 !important;
}

.brand-link .brand-image {
  float: none;
  line-height: 0.8;
  margin-left: 0.2rem;
  margin-right: 0.5rem;
  margin-top: -3px;
  max-height: 58px;
  width: auto;
}

.brand-link .brand-text{
  font-size: 18px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
} 