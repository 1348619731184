.modul_sec_wrapper {
    /* background-color: #ffffff; */
    /* padding: 15px; */
}

.tabs_wrapper {
    width: 100%;
    padding: 20px 10px;
    border-radius: 0 0 8px 8px;
    /* margin-bottom: 30px; */
    background-color: #fff;
    margin-bottom: 30px;
    position: relative;
}

.tabs_wrapper>.btn {
    position: absolute;
    right: 10px;
    top: 20px;
}

.modul_sec_wrapper .content {
    width: 100%;
    padding: 30px 15px;
    border-radius: 0 0 8px 8px;
    background-color: #fff;
    /* border: 1px solid #dee2e6; */
    border-top: 0;
    margin-bottom: 30px;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
    font-weight: 600;
}

.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
    background-color: #fff;
    color: #000;
}

.nav-tabs .nav-link {
    transition: all 0.3s;
    color: #000;
}

.cross_close {
    flex: 0 0 14px;
    width: 14px;
    height: 14px;
    font-size: 8px;
    background-color: red;
    color: #fff;
    border-radius: 50%;
    border: 0;
    padding: 0;
    display: inline-block;
    transition: all 0.3s;
}

.cross_close:hover {
    background-color: #000;
}

.form_wrapper {
    column-gap: 5px;
}

.list_module_wrapper {
    background-color: #f5f5f5;
    padding: 10px;
    border-radius: 4px;
    margin-top: 10px;
    border: 1px solid #eaeaea;
}

.list_module_wrapper,
.content_inner_wrap {
    position: relative;
}

.list_module_wrapper>.cross_close,
.content_inner_wrap>.cross_close {
    position: absolute;
    right: -7px;
    top: -7px;
}

.content_inner_wrap {
    padding: 10px;
    border: 1px solid #eaeaea;
    margin-bottom: 10px;
}

.quantity_input {
    max-width: 100px;
}

.grey_wrapper_title {
    padding-bottom: 15px;
    font-weight: 700;
    border-bottom: 1px solid #d1d1d1;
}

/* .table.invoice_wrapper, */
.table_container {
    /* max-width: 1350px; */
    margin: 0 auto;
}

.table.invoice_wrapper td,
.table.invoice_wrapper th {
    padding: 5px 10px;
}

table.invoice_wrapper {
    padding: 0;
}

.invoice_wrapper>tbody>tr>td {
    border: 0;
}

.invoice_wrapper>thead>tr>th,
.invoice_inner_wrapper>thead>tr>th {
    background-color: #eaeaea;
    border-top: 1px solid #000000;
    border-bottom: 1px solid #000000;
    border-right: 0;
}

.invoice_wrapper>thead>tr>th:nth-child(1) {
    width: 15%;
    border-left: 1px solid #000000;
}

.invoice_wrapper>thead>tr>th:nth-child(2) {
    width: 35%;
}

.invoice_wrapper>thead>tr>th:nth-child(3) {
    width: 5%;
}

.invoice_wrapper>thead>tr>th:nth-child(4) {
    width: 55%;
    text-align: center;
    border-right: 1px solid #000000;
}

.invoice_inner_wrapper>thead>tr>th:nth-child(1) {
    width: 15%;
    /* border-left: 1px solid #000000; */
}

.invoice_inner_wrapper>thead>tr>th:nth-child(2) {
    width: 30%;
}

.invoice_inner_wrapper>thead>tr>th:nth-child(3) {
    width: 10%;
}

.invoice_inner_wrapper>thead>tr>th:nth-child(4) {
    width: 15%;
}

.invoice_inner_wrapper>thead>tr>th:nth-child(5) {
    width: 15%;
}

.invoice_inner_wrapper>thead>tr>th:nth-child(6) {
    width: 15%;
    border-right: 1px solid #000000;
}

.invoice_inner_wrapper {
    border: 1px solid #000000;
}

.invoice_inner_wrapper,
.invoice_inner_wrapper>tbody>tr>td {
    padding: 0;
    font-size: 16px;
}

.invoice_inner_wrapper thead>tr>th:first-child,
.invoice_inner_wrapper tbody>tr>td:first-child {
    text-align: left;
    padding-left: 20px;
}

.invoice_inner_wrapper thead>tr>th:nth-child(2),
.invoice_inner_wrapper tbody>tr>td:nth-child(2) {
    text-align: left;
}

.invoice_inner_wrapper thead>tr>th,
.invoice_inner_wrapper tbody>tr>td {
    text-align: left;
}

.total_table,
.payment_table {
    border: 0;
}

.total_table thead>tr>th,
.payment_table thead>tr>th {
    background-color: transparent;
    border: 0;
}

.total_table thead>tr>th:first-child {
    width: 20%;
}

.total_table thead>tr>th:nth-child(2) {
    width: 18%;
    text-align: right;
}

.total_table thead>tr>th:nth-child(3) {
    width: 10%;
    text-align: left;
    font-weight: 400;
}

.payment_table thead>tr>th {
    border: 2px solid rgba(252, 10, 1, 0.5) !important;
    background-color: rgba(252, 10, 1, 0.5);
}

.total_payble_title {
    border-left: 2px solid rgba(252, 10, 1, 0.5) !important;
    border-top: 2px solid rgba(252, 10, 1, 0.5) !important;
    border-bottom: 2px solid rgba(252, 10, 1, 0.5) !important;
}

.total_payble_amt {
    border-right: 2px solid rgba(252, 10, 1, 0.5) !important;
    border-top: 2px solid rgba(252, 10, 1, 0.5) !important;
    border-bottom: 2px solid rgba(252, 10, 1, 0.5) !important;
}

.invoice_inner_wrapper .free-processing{
    padding: 1.01em 16px;
    /* border-left: 5px solid #2196F3 !important; */
    border-left: 6px solid #ccc !important;
    color: #000 !important;
    background-color: #ddffff !important;
    border-color: #2196F3 !important;
}

.payment_table thead>tr>th:first-child {
    width: 75%;
    border: 0px !important;
    background-color: transparent;
}

.payment_table thead>tr>th:nth-child(2) {
    width: 15%;
    text-align: right;
    border-right: 0px !important;
}

.payment_table thead>tr>th:nth-child(3) {
    width: 15%;
    text-align: left;
    border-left: 0px !important;
    font-weight: 400;
}

.modul_sec_wrapper .container-fluid label {
    font-weight: 400 !important;
}

.invoice_wrapper.table,
.invoice_inner_wrapper,
.invoice_inner_wrapper>tbody>tr>td {
    font-size: 14px;
}

@media (max-width:1199px) {
    .list_module_wrapper {
        padding: 10px 15px;
    }
}

@media (max-width:991px) {
    .tabs_wrapper {
        margin-bottom: 30px;
        padding: 20px 0px;
    }
}

@media (max-width:767px) {
    .table.invoice_wrapper {
        width: 940px;
    }
}

@media (max-width:575px) {
    .invoice_wrapper tbody tr td {
        font-size: 14px;
    }
}

@media (max-width:374px) {
    .tabs_wrapper>.btn {
        display: none;
    }
}